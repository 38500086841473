import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Header from "../components/header"

const Youtube = ({ url }) => (
  <Col
    xs={6}
    style={{
      padding: `10px`,
    }}
  >
    <style>{`.video-container { position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; }
.video-container iframe, .video-container object, .video-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }`}</style>
    <div class="video-container">
      <iframe
        src={url}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </Col>
)

const Media = () => (
  <Layout>
    <SEO title="Media" />
    <Header title="Media" />
    <Container
      fluid="md"
      style={{
        padding: `0 2rem`,
      }}
    >
      <Row>
        <Col>
          <h2
            style={{
              margin: `4rem 0`,
            }}
          >
            Soloist
          </h2>
        </Col>
      </Row>
      <Row>
        <Youtube url="https://www.youtube.com/embed/VVv-cbymVas" />
        <Youtube url="https://www.youtube.com/embed/MuX28SVM4Xk" />
        <Youtube url="https://www.youtube.com/embed/eaK5skya-Mo" />
      </Row>

      <Row>
        <Col>
          <h2
            style={{
              margin: `4rem 0`,
            }}
          >
            In Recital
          </h2>
        </Col>
      </Row>
      <Row>
        <Youtube url="https://www.youtube.com/embed/s_Yodg2S3Iw" />
        <Youtube url="https://www.youtube.com/embed/6B-VEcmZa2E" />
        <Youtube url="https://www.youtube.com/embed/aU4fX5H6Sho" />
        <Youtube url="https://www.youtube.com/embed/gJJ0Z1h4olc" />
        <Youtube url="https://www.youtube.com/embed/FtC3LdT7OAA" />
        <Youtube url="https://www.youtube.com/embed/aWdb7E9-QvI" />
        <Youtube url="https://www.youtube.com/embed/2q5fwfOl4d4" />
        <Youtube url="https://www.youtube.com/embed/iUj90DQ8Gh8" />
      </Row>
      <Row>
        <Col>
          <h2
            style={{
              margin: `4rem 0`,
            }}
          >
            Chamber Music
          </h2>
        </Col>
      </Row>
      <Row>
        <Youtube url="https://www.youtube.com/embed/ikUEz-hVMLM" />
        <Youtube url="https://www.youtube.com/embed/griASke6lco" />
      </Row>
    </Container>
  </Layout>
)

export default Media
